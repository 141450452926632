/**
 * Abstracts
 * Variablen und scss spezifische Methoden zur Vereinfachung
 */
@import "abstracts/mixins";
@import "abstracts/functions";

/**
 * Settings
 */
@import "settings/grid";
@import "settings/fluid-type";
@import "settings/colors";
@import "settings/font-sizes";
@import "settings/utilities";
@import "settings/fonts";
@import "settings/aspect-ratios";

/**
 * Base
 * Basiskonfiguration von HTML Elementen (z.B h1)
 * Eigenschaften werden auf globale Elemente oder spezifische Stylings angewandt
 */
@import "base/reset";
@import "base/fonts";
@import "base/scaffolding";
@import "base/headings";
@import "base/grid";
@import "base/aspect-ratios";
@import "base/utilities";

/**
 * Vendor
 * Dateien von Drittanbietern (z.B. Bootstrap, Lightboxes)
 */
// @import "vendor/example-folder";
@import "hover.css";

/**
 * Elements
 * Hier entstehen kleinteilige Komponenten (z.B. Button)
 */
@import "elements/button";
@import "elements/qualification";
@import "elements/social-link";

/**
 * Components
 * Hier entstehen komplexere Komponenten (z.B. Navigation)
 */
@import "components/header";
@import "components/navigation";
@import "components/footer";
@import "components/section";
@import "components/sidebar";
@import "components/menu";
@import "components/menu-opener";
@import "components/timeline";

/**
 * Shame
 * Hier werden sämtliche Hacks an bereits bestehenden CSS Eigenschaften eingetragen
 */
@import "shame";
